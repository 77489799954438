import React from 'react'
import { Link } from 'react-router-dom';
import './products.css'

import { GiShieldReflect } from "react-icons/gi";
import { MdNightlightRound } from "react-icons/md";
import { IoSpeedometerOutline } from "react-icons/io5";
import { MdSpeed } from "react-icons/md";
import { GiWeight } from "react-icons/gi";

import m1 from '../assets/products/mamba.png'
// import f1 from '../assets/services/csk.png'

import appm1 from '../assets/Applications/secu_serv.jpg'
import appm2 from '../assets/Applications/delivery.jpeg'
import appm3 from '../assets/Applications/disaster managment.jpeg'


const CS_mamba = () => {
    const onButtonClick = () => {
        const pdfUrl = "CS-Mamba_Brochure.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "CS-Mamba_Brochure.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <>
            <div className='CS_promain'>
                <div className='CS_proHeropage'>
                    <div className='border'>
                        <div className='CS_proHeroimg'>
                            <img src={m1} class="img-fluid" alt="..." width={400}></img>
                        </div>
                        <h1>CS-MAMBA</h1>
                        <h5>Surveillence Drone</h5>
                        <div>
                            <button type="button" class="btn btn-primary" onClick={onButtonClick}>Download Brochure</button>
                            <Link to='/contactus'><button type="button" class="btn btn-primary">Enquire</button></Link>
                        </div>
                    </div>
                </div>
                <div className='CS_prodata'>
                    <div className='CS_proInfo'>
                        <h1>Information</h1>
                        <p>
                        The CS-MAMBA quadcopter represents a pioneering advancement in drone technology, distinguished by its multifaceted capabilities including surveillance, disaster management, and mapping. This cutting-edge quadcopter is designed to excel in a variety of tasks, making it an indispensable tool across different sectors. It's versatility allows it to effectively conduct surveillance operations, providing crucial intelligence and monitoring capabilities. In disaster management scenarios, the CS-MAMBA proves invaluable by facilitating rapid response efforts, assessing damage, and coordinating rescue operations from the air. Additionally, it's mapping functionality enables precise data collection and analysis, aiding in urban planning, environmental monitoring, and infrastructure development. CS-MAMBA's ability to seamlessly integrate multiple functions makes it a standout solution for diverse applications, underscoring it's significance in modern aerial technology.</p>
                    </div>
                    <div className='CS_proApplication'>
                        <div className='CS_application'>
                            <h1>Applications</h1>
                            <figure class="figure">
                                <img src={appm1} class="figure-img img-fluid rounded" alt="..." />
                                <figcaption class="figure-caption">Security & Surveillance</figcaption>
                            </figure>
                            <figure class="figure">
                                <img src={appm2} class="figure-img img-fluid rounded" alt="..." />
                                <figcaption class="figure-caption">Payload </figcaption>
                            </figure>
                            <figure class="figure">
                                <img src={appm3} class="figure-img img-fluid rounded" alt="..." />
                                <figcaption class="figure-caption">Disaster Management</figcaption>
                            </figure>
                        </div>
                    </div>
                    <div className='CS_proFeature'>
                        <div className='CS_Feature'>
                            <h1>Features</h1>
                            <div class="container text-center">
                                <div class="row justify-content-md-center">
                                    <div class="col col-lg-2">
                                        <h1><IoSpeedometerOutline /></h1>
                                        <h5>Flight Time 55 min</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><GiShieldReflect /></h1>
                                        <h5>Obstacle Avoidance</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><MdNightlightRound /></h1>
                                        <h5>Night Vision</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><MdSpeed /></h1>
                                        <h5>Max Speed 10m/s</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><GiWeight /></h1>
                                        <h5>2 kg Payload Delivery</h5>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='Sqaure'></div> */}
                </div>
            </div>
        </>
    )
}

export default CS_mamba