import React, { useEffect } from 'react'
import './CSIAcademy.css'
import Marquee from "react-fast-marquee";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


import himg1 from '../assets/rpto/Inaguration/1.JPG'
import himg2 from '../assets/rpto/Inaguration/2.JPG'

// import bg1 from '../assets/rpto/bg1.jpeg'
import logo from '../assets/conlogo.png'
import img1 from '../assets/rpto/rpto1.jpg'
import img3 from '../assets/rpto/rpto3.jpg'
import img4 from '../assets/rpto/rpto4.jpg'
import img5 from '../assets/rpto/rpto5.jpg'
import img6 from '../assets/rpto/rpto6.jpg'
import img8 from '../assets/rpto/rpto8.jpg'
import img9 from '../assets/rpto/rpto9.jpg'
import img10 from '../assets/rpto/rpto10.jpg'
import img11 from '../assets/rpto/rpto11.jpg'
import img12 from '../assets/rpto/rpto12.jpg'
import img13 from '../assets/rpto/rpto13.jpg'

import img16 from '../assets/rpto/Simulator.jpg'
import img17 from '../assets/rpto/rpto16.jpg'
import img18 from '../assets/rpto/rpto18.jpg'
import img19 from '../assets/rpto/rpto19.jpg'
import img20 from '../assets/rpto/Theory.jpg'
// import img21 from '../assets/rpto/rpto21.jpg'

import trn1 from '../assets/rpto/Inaguration/11.jpg'
import trn2 from '../assets/rpto/Inaguration/6.jpg'
import trn3 from '../assets/rpto/Inaguration/7.jpg'
import trn4 from '../assets/rpto/Inaguration/8.jpg'
import trn6 from '../assets/rpto/Inaguration/20.jpg'
import trn7 from '../assets/rpto/Inaguration/22.jpg'

import about from '../assets/rpto/aboutUS.png'

import ring from '../assets/rpto/ring.png'

import m1 from '../assets/team/m1.png'
import r1 from '../assets/team/r1.png'


//Our partners
import op1 from '../assets/rpto/ourPartners/jscoe.jpeg'
import op2 from '../assets/rpto/ourPartners/jspm.jpeg'
import op3 from '../assets/rpto/ourPartners/mit.jpeg'
import op4 from '../assets/rpto/ourPartners/vnmkv.png'
import op5 from '../assets/rpto/ourPartners/aissms.png'
import op6 from '../assets/rpto/ourPartners/dpes.jpeg'

//Our Major Recruiters
import omr1 from '../assets/rpto/ourPartners/Csilogo.png'
import omr2 from '../assets/rpto/ourPartners/techbot.png'



const CSIAcademy = () => {
    useEffect(() => {
        AOS.init();
    })
    return (
        <>
            <div className="container">
                <div className='my-3' >
                    <div id="carouselExampleAutoplaying" className="carousel slide " data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="1300">
                                <img src={himg2} className="d-block w-100" alt="..." />
                            </div>
                            <div className="carousel-item " data-bs-interval="1300">
                                <img src={himg1} className="d-block w-100" alt="..." />
                            </div>
                            <div className="carousel-item" data-bs-interval="1300">
                                <img src={trn1} className="d-block w-100 img-fluid" alt="..." />
                            </div>
                            <div className="carousel-item" data-bs-interval="1300">
                                <img src={trn2} className="d-block w-100 img-fluid" alt="..." />
                            </div>
                            <div className="carousel-item" data-bs-interval="1300">
                                <img src={trn3} className="d-block w-100 img-fluid" alt="..." />
                            </div>
                            <div className="carousel-item" data-bs-interval="1300">
                                <img src={trn4} className="d-block w-100 img-fluid" alt="..." />
                            </div>
                            {/* <div className="carousel-item" data-bs-interval="1300">
                                <img src={trn5} className="d-block w-100 img-fluid" alt="..." />
                            </div> */}
                            <div className="carousel-item" data-bs-interval="1300">
                                <img src={trn6} className="d-block w-100 img-fluid" alt="..." />
                            </div>
                            <div className="carousel-item" data-bs-interval="1300">
                                <img src={trn7} className="d-block w-100 img-fluid" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                </div>


                <div className="aboutRPTO d-flex flex-wrap justify-content-center align-items-center" data-aos="zoom-in" style={{margin:"100px auto"}}>
                    <div className='d-flex flex-wrap justify-content-center align-items-center m-3'>
                        <img className='rpto-about-img1' src={about} alt="..." height={400} />
                        <div className='rpto-about-img2 '>
                            <img src={logo} alt="..." height={100} />
                            <h1 className='text-center' style={{ fontFamily: " 'Times New Roman', Times, sans-serif" }}>Cerebrospark Academy</h1>
                        </div>
                    </div>
                    <div className='about-Data'>
                        <h1 className='border-bottom border-1 border-black'>About RPTO</h1>
                        <p>
                            We are Cerebrospark Academy, a Company based in Pune, Maharashtra. We are Directorate General of Civil Aviation (DGCA) Authorised Remote Pilot Training Organization (RPTO). "CEREBROSPARK ACADEMY" is dedicated to provide the highest quality remote pilot training services. We are committed to safety, excellence, and continuous improvement in all operations. We aim to produce competent and responsible remote pilots who meet industry standards and contribute positively to drone safety.
                        </p>
                    </div>
                </div>


                <div style={{margin:"100px auto"}}>
                    <h1 className='text-center main-heading' data-aos="zoom-in">DGCA Certified RPAS Training Program</h1>
                    <div className='Training_Program'>
                        <div className='TP_div'>
                            <div class="card" style={{ width: "14rem" }}>
                                <img src={img20} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Theory Classes</h5>
                                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                        Read More ...
                                    </button>
                                </div>
                            </div>


                            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Theory Classes</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <ul>
                                                <li> DGCA Rules and Regulations</li>
                                                <li> UAS operator registration and Authorizations</li>
                                                <li> Basic principles of flight</li>
                                                <li> Fixed wing / Rotary wing / Hybrid operations</li>
                                                <li> UAS integration and Maintenance</li>
                                                <li> Payload Utilization</li>
                                                <li> Drone Data Analysis</li>
                                                <li> Emergency Protocol and Procedures</li>
                                                <li> Weather and Meterology</li>
                                                <li> ATC Procedures and Radio Telephony</li>
                                                <li> Digital Sky familiarization</li>
                                                <li> UAS registration</li>
                                            </ul>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='TP_div'>
                            <div class="card" style={{ width: "14rem" }}>
                                <img src={img16} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Simulator Training Sessions</h5>
                                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop3">
                                        Read More ...
                                    </button>
                                </div>
                            </div>


                            <div class="modal fade" id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Simulator Training Sessions</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <ul>
                                                <li> Identical UAS flight training</li>
                                                <li> Various atmospheric conditions dynamic wind conditions</li>
                                                <li> Numerous shapes and pattern of flight</li>
                                                <li> Emergency Recovery</li>
                                            </ul>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='TP_div'>
                            <div class="card" style={{ width: "14rem" }}>
                                <img src={img19} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Ground Training Sessions</h5>
                                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop2">
                                        Read More ...
                                    </button>
                                </div>
                            </div>


                            <div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Ground Training Sessions</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <ul>
                                                <li> Hands-on UAS Training</li>
                                                <li> Assembly maintenance and handling</li>
                                                <li> Ground Control Station (GCS) Training</li>
                                                <li> Flight Logging</li>
                                                <li> Troubleshooting</li>
                                            </ul>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='TP_div'>
                            <div class="card" style={{ width: "14rem" }}>
                                <img src={img18} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">UAS Flying Training Sessions</h5>
                                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop1">
                                        Read More ...
                                    </button>
                                </div>
                            </div>


                            <div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="staticBackdropLabel">UAS Flying Training Sessions</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <ul>
                                                <li> Pre-flight training</li>
                                                <li> Flight Training accompanied by Instructor (Dual transmitters)</li>
                                                <li> UAS flight manoeuvres</li>
                                                <li> Take-off and landing</li>
                                                <li> Hovering</li>
                                                <li> Flying at various conditions, numerous shapes and patterns of light</li>
                                                <li> Emergency Recovery and Landing Training</li>
                                                <li> Post flight training</li>

                                            </ul>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='TP_div'>
                            <div class="card" style={{ width: "14rem" }}>
                                <img src={img17} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Practice Sessions</h5>
                                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop4">
                                        Read More ...
                                    </button>
                                </div>
                            </div>


                            <div class="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Practice Sessions</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <ul>
                                                <li>More Tests</li>
                                                <li>Best Practice</li>
                                            </ul>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div>

                    </div>
                </div>


                <div className='career' style={{margin:"100px auto"}}>
                    <h1>Make Your Career as Drone Pilot</h1>
                    <div>

                        <button className='btn btn-primary'><a className='text-white' href="https://forms.gle/RmrFZt4mUS65zsvh6">Enquire Now</a></button>
                    </div>
                </div>

                <div className="courseDurElig" data-aos="zoom-in" style={{margin:"100px auto"}}>
                    <div className="CDE">
                        <div className='coDuration '>
                            <img src={ring} alt="" height={200} width={220} />
                            <h1>5 Days</h1>
                            <h6>Course Duration</h6>
                        </div>
                    </div>
                    <div className="CDE">
                        <div className="coDuration coEligibility">
                            <img src={ring} alt="" height={200} width={220} />
                            <h3>Eligibility</h3>
                            <ul>
                                <li>10/SSC Pass</li>
                                <li>18-65 years of Age</li>
                            </ul>
                        </div>

                    </div>
                </div>



                <div className="faciInfra" data-aos="zoom-in" style={{margin:"100px auto"}}>
                    <h1 className='main-heading'>Facilities and Infrastructure</h1>
                    <div className='d-flex flex-wrap  justify-content-center align-items-center gap-2'>
                        <p className='border border-1 border-black rounded-2 p-2'><i className="bi bi-record-circle text-warning"></i> 100% Placement Assistance</p>
                        <p className='border border-1 border-black rounded-2 p-2'><i className="bi bi-record-circle text-warning"></i> Highly skilled and experienced Instructors.</p>
                        <p className='border border-1 border-black rounded-2 p-2'><i className="bi bi-record-circle text-warning"></i> DGCA approved small class drones.</p>
                        <p className='border border-1 border-black rounded-2 p-2'><i className="bi bi-record-circle text-warning"></i> State of art drone laboratory with assembly and dismantling kit.</p>
                        <p className='border border-1 border-black rounded-2 p-2'><i className="bi bi-record-circle text-warning"></i> Latest flight simulator, Smart class rooms and lecture hall.</p>
                        <p className='border border-1 border-black rounded-2 p-2'><i className="bi bi-record-circle text-warning"></i> On-campus Lodging and boarding facility.</p>
                    </div>
                </div>

                <div className='slidingImages' style={{margin:"100px auto"}}>
                    <Marquee>
                        <img className='rounded-3 vcard' src={img1} alt="" height={290} />
                        <img className='rounded-3 vcard' src={img3} alt="" height={290} />
                        <img className='rounded-3 vcard' src={img4} alt="" height={290} />
                        <img className='rounded-3 vcard' src={img5} alt="" height={290} />
                        <img className='rounded-3 vcard' src={img6} alt="" height={290} />
                        <img className='rounded-3 vcard' src={img8} alt="" height={290} />
                        <img className='rounded-3 vcard' src={img9} alt="" height={290} />
                        <img className='rounded-3 vcard' src={img10} alt="" height={290} />
                        <img className='rounded-3 vcard' src={img11} alt="" height={290} />
                        <img className='rounded-3 vcard' src={img12} alt="" height={290} />
                        <img className='rounded-3 vcard' src={img13} alt="" height={290} />
                    </Marquee>
                </div>

                <div className="contactRPTO my-5" data-aos="zoom-in" style={{margin:"100px auto"}}>
                    <h1 className='main-heading'>RPTO Officials :</h1>
                    <div className='contactContainer'>
                        <div className="card" data-aos="fade-up">
                            <img src={r1} className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5>Mr. Rushikesh B. Sonawane</h5>
                                <p>Accountable Manager & Remote Pilot Instructor</p>
                                <p><i className="bi bi-telephone-fill"></i> +91 7499884991</p>
                            </div>
                        </div>
                        <div className="card" data-aos="fade-up">
                            <img src={m1} className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5>Mr. Mihir M. Kedar</h5>
                                <p>Chief Remote Pilot Instructor</p>
                                <p><i className="bi bi-telephone-fill"></i> +91 7387515448 </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='my-5' style={{margin:"100px auto"}}>
                    <h1 className='text-center main-heading' data-aos="zoom-in">Our Partners</h1>
                    <div className="our-partners d-flex flex-wrap justify-content-center align-items-center" data-aos="fade-down">
                        <img className="img fluid m-2 vcard p-3" src={op1} alt="..." height={120} />
                        <img className="img fluid m-2 vcard p-3" src={op2} alt="..." height={120} />
                        <img className="img fluid m-2 vcard p-3" src={op3} alt="..." height={120} />
                        <img className="img fluid m-2 vcard p-3" src={op4} alt="..." height={120} />
                        <img className="img fluid m-2 vcard p-3" src={op5} alt="..." height={120} />
                        <img className="img fluid m-2 vcard p-3" src={op6} alt="..." height={120} />
                    </div>
                </div>
                <div className='my-5' style={{margin:"100px auto"}}>
                    <h1 className='text-center main-heading' data-aos="zoom-in">Our Major Recruiters</h1>
                    <div className="our-partners d-flex flex-wrap justify-content-center align-items-center" data-aos="fade-down">
                        <img className="img fluid m-2 vcard p-3" src={omr1} alt="..." height={150} />
                        <img className="img fluid m-2 vcard p-3" src={omr2} alt="..." height={150} />
                    </div>
                </div>
            </div>

        </>
    )
}

export default CSIAcademy
