import React from 'react'
import { SlideshowLightbox } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import './Gallary.css'


import act1 from '../assets/Gallary/Activities/kj1.jpeg'
import act2 from '../assets/Gallary/Activities/kj2.png'
import act3 from '../assets/Gallary/Activities/kj3.png'
import act23 from '../assets/Gallary/Activities/sky1.png'
import act24 from '../assets/Gallary/Activities/sky2.png'
import act25 from '../assets/Gallary/Activities/sky1.png'
import act4 from '../assets/Gallary/Activities/sky3.png'
import act5 from '../assets/Gallary/Activities/sky4.png'
import act6 from '../assets/Gallary/Activities/sky5.png'
import act7 from '../assets/Gallary/Activities/sky6.png'
import act8 from '../assets/Gallary/Activities/sky7.png'
import act9 from '../assets/Gallary/Activities/sky9.png'
import act10 from '../assets/Gallary/Activities/sky10.png'
import act11 from '../assets/Gallary/Activities/sky11.png'
import act12 from '../assets/Gallary/Activities/sky12.png'
import act13 from '../assets/Gallary/Activities/sky13.png'
import act14 from '../assets/Gallary/Activities/sky14.png'
import act15 from '../assets/Gallary/Activities/sky15.png'
import act16 from '../assets/Gallary/Activities/sky16.png'
import act17 from '../assets/Gallary/Activities/sky17.png'
import act18 from '../assets/Gallary/Activities/sky18.png'
import act19 from '../assets/Gallary/Activities/sky19.png'
import act20 from '../assets/Gallary/Activities/sky20.png'
import act21 from '../assets/Gallary/Activities/sky21.png'
import act22 from '../assets/Gallary/Activities/sky22.png'

import onf1 from '../assets/Gallary/Onfield/1.png'
import onf2 from '../assets/Gallary/Onfield/2.png'
import onf3 from '../assets/Gallary/Onfield/3.png'
import onf4 from '../assets/Gallary/Onfield/4.png'
import onf5 from '../assets/Gallary/Onfield/5.png'
import onf6 from '../assets/Gallary/Onfield/6.png'
import onf7 from '../assets/Gallary/Onfield/7.png'
import onf8 from '../assets/Gallary/Onfield/8.png'
import onf9 from '../assets/Gallary/Onfield/9.png'
import onf10 from '../assets/Gallary/Onfield/10.png'
import onf11 from '../assets/Gallary/Onfield/11.png'

const Activities = () => {
  return (
    <>
      <div className='gallary'>
                {/* <h1><span>All About The CSI</span></h1> */}
                <SlideshowLightbox className='container gallaryContainer grid grid-cols-3 gap-2 mx-auto'>
                    <img className='w-full rounded' src={act1} alt='...' />
                    <img className='w-full rounded' src={act2} alt='...' />
                    <img className='w-full rounded' src={act3} alt='...' />
                    <img className='w-full rounded' src={act4} alt='...' />
                    <img className='w-full rounded' src={act5} alt='...' />
                    <img className='w-full rounded' src={act6} alt='...' />
                    <img className='w-full rounded' src={act7} alt='...' />
                    <img className='w-full rounded' src={act8} alt='...' />
                    <img className='w-full rounded' src={act9} alt='...' />
                    <img className='w-full rounded' src={act10} alt='...' />
                    <img className='w-full rounded' src={act11} alt='...' />
                    <img className='w-full rounded' src={act12} alt='...' />
                    <img className='w-full rounded' src={act13} alt='...' />
                    <img className='w-full rounded' src={act14} alt='...' />
                    <img className='w-full rounded' src={act15} alt='...' />
                    <img className='w-full rounded' src={act16} alt='...' />
                    <img className='w-full rounded' src={act17} alt='...' />
                    <img className='w-full rounded' src={act18} alt='...' />
                    <img className='w-full rounded' src={act19} alt='...' />
                    <img className='w-full rounded' src={act20} alt='...' />
                    <img className='w-full rounded' src={act21} alt='...' />
                    <img className='w-full rounded' src={act22} alt='...' />
                    <img className='w-full rounded' src={act23} alt='...' />
                    <img className='w-full rounded' src={act24} alt='...' />
                    <img className='w-full rounded' src={act25} alt='...' />
                    <img className='w-full rounded' src={onf1} alt='...' />
                    <img className='w-full rounded' src={onf2} alt='...' />
                    <img className='w-full rounded' src={onf3} alt='...' />
                    <img className='w-full rounded' src={onf4} alt='...' />
                    <img className='w-full rounded' src={onf5} alt='...' />
                    <img className='w-full rounded' src={onf6} alt='...' />
                    <img className='w-full rounded' src={onf7} alt='...' />
                    <img className='w-full rounded' src={onf8} alt='...' />
                    <img className='w-full rounded' src={onf9} alt='...' />
                    <img className='w-full rounded' src={onf10} alt='...' />
                    <img className='w-full rounded' src={onf11} alt='...' />

                </SlideshowLightbox>
            </div>
    </>
  )
}

export default Activities
