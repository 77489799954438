import React from 'react'
import { SlideshowLightbox } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import './Gallary.css'


import trn1 from '../assets/Gallary/Training/1.jpg'
import trn2 from '../assets/Gallary/Training/2.jpg'
import trn3 from '../assets/Gallary/Training/3.jpg'
import trn4 from '../assets/Gallary/Training/4.jpg'
import trn5 from '../assets/Gallary/Training/5.jpg'
import trn6 from '../assets/Gallary/Training/6.jpg'
import trn7 from '../assets/Gallary/Training/7.jpg'
import trn8 from '../assets/Gallary/Training/8.jpg'
import trn9 from '../assets/Gallary/Training/9.jpg'
import trn10 from '../assets/Gallary/Training/10.jpg'
import trn11 from '../assets/Gallary/Training/11.jpg'
import trn12 from '../assets/Gallary/Training/12.jpg'
import trn13 from '../assets/Gallary/Training/13.jpg'
import trn14 from '../assets/Gallary/Training/14.jpg'
import trn15 from '../assets/Gallary/Training/15.jpg'
import trn16 from '../assets/rpto/rpto16.jpg'
import trn17 from '../assets/rpto/rpto17.jpg'
import trn18 from '../assets/rpto/rpto18.jpg'
import trn19 from '../assets/rpto/rpto19.jpg'
import trn20 from '../assets/rpto/rpto20.jpg'
import trn21 from '../assets/rpto/rpto21.jpg'
import trn22 from '../assets/Gallary/Training/20.jpg'
import trn23 from '../assets/Gallary/Training/22.jpg'
import trn24 from '../assets/rpto/rpto16.jpg'
import trn25 from '../assets/rpto/rpto22.jpg'


const Training = () => {
  return (
    <>
      <div className='gallary'>
                {/* <h1><span>All About The CSI</span></h1> */}
                <SlideshowLightbox className='container gallaryContainer grid grid-cols-3 gap-2 mx-auto'>
                    <img className='w-full rounded' src={trn2} alt='...' />
                    <img className='w-full rounded' src={trn3} alt='...' />
                    <img className='w-full rounded' src={trn4} alt='...' />
                    <img className='w-full rounded' src={trn5} alt='...' />
                    <img className='w-full rounded' src={trn6} alt='...' />
                    <img className='w-full rounded' src={trn7} alt='...' />
                    <img className='w-full rounded' src={trn8} alt='...' />
                    <img className='w-full rounded' src={trn9} alt='...' />
                    <img className='w-full rounded' src={trn10} alt='...' />
                    <img className='w-full rounded' src={trn11} alt='...' />
                    <img className='w-full rounded' src={trn12} alt='...' />
                    <img className='w-full rounded' src={trn13} alt='...' />
                    <img className='w-full rounded' src={trn14} alt='...' />
                    <img className='w-full rounded' src={trn15} alt='...' />
                    <img className='w-full rounded' src={trn15} alt='...' />
                    <img className='w-full rounded' src={trn16} alt='...' />
                    <img className='w-full rounded' src={trn17} alt='...' />
                    <img className='w-full rounded' src={trn18} alt='...' />
                    <img className='w-full rounded' src={trn19 } alt='...' />
                    <img className='w-full rounded' src={trn20} alt='...' />
                    <img className='w-full rounded' src={trn21} alt='...' />
                    <img className='w-full rounded' src={trn22} alt='...' />
                    <img className='w-full rounded' src={trn23} alt='...' />
                    <img className='w-full rounded' src={trn24} alt='...' />
                    <img className='w-full rounded' src={trn25} alt='...' />
                    <img className='w-full rounded' src={trn1} alt='...' />
                </SlideshowLightbox>
            </div>
    </>
  )
}

export default Training
