import React from 'react'
import './App.css'

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './pages/Navbar'
import Footer from './pages/Footer'
import About from './pages/About'
import CS_krishi from './product pages/CS_krishi'
import Home from './pages/Home'
import Gallary from './pages/Gallary';
import Sparkathon from './pages/Sparkathon';
import CSIAcademy from './pages/CSIAcademy';
import Services from './pages/Services';
import CS_bee from './product pages/CS_bee';
import CS_mamba from './product pages/CS_mamba';
import CS_pride from './product pages/CS_pride';
import CS_pavanputra from './product pages/CS_pavanputra';
import CS_vtol from './product pages/CS_vtol';
import Event from './pages/Event';
import Activities from './pages/Activities';
import Awards_reco from './pages/Awards_reco';
import Meetup from './pages/Meetup';
import Collaboration from './pages/Collaboration';
import Training from './pages/Training';
import Workshop from './pages/Workshop';
import ContactUs from './pages/ContactUs';
import Course1 from './pages/Course1';
import Course2 from './pages/Course2';



const App = () => {
  return (
    <body>
      <Router >
        <Navbar />
        <Routes>
          {/* <Route index element={<Home />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/krishi" element={<CS_krishi />} />
          <Route path="/csiacademy" element={<CSIAcademy/>} />
          <Route path="/service" element={<Services/>} />
          <Route path="/sparkathon" element={<Sparkathon/>} />
          <Route path="/about" element={<About />} />
          <Route path="/gallary" element={<Gallary/>} />
          <Route path="/bee" element={<CS_bee />} />
          <Route path="/mamba" element={<CS_mamba />} />
          <Route path="/pride" element={<CS_pride />} />
          <Route path="/vtol" element={<CS_vtol />} />
          <Route path="/pavanputra" element={<CS_pavanputra />} />

          <Route path="/course1" element={<Course1/>} />
          <Route path="/course2" element={<Course2/>} />
          <Route path="/event1" element={<Event/>} />
          <Route path="/workshop" element={<Workshop/>} />
          <Route path="/training" element={<Training/>} />
          <Route path="/awd_rec" element={<Awards_reco/>} />
          <Route path="/meetup" element={<Meetup/>} />
          <Route path="/collaboration" element={<Collaboration/>} />
          <Route path="/activities" element={<Activities/>} />
          <Route path="/contactus" element={<ContactUs/>} />
        </Routes>
        <Footer />
        
      </Router>
    </body>
  )
}

export default App