import React from 'react'
import { SlideshowLightbox } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import './Gallary.css'
import ev1 from '../assets/Gallary/Event/evnt1.png'
import ev2 from '../assets/Gallary/Event/evnt2.png'
import ev3 from '../assets/Gallary/Event/evnt3.png'
import ev4 from '../assets/Gallary/Event/evnt4.png'
import ev5 from '../assets/Gallary/Event/evnt5.png'
import ev6 from '../assets/Gallary/Event/evnt6.png'
import ev7 from '../assets/Gallary/Event/evnt7.png'
import ev8 from '../assets/Gallary/Event/evnt8.png'
import ev9 from '../assets/Gallary/Event/evnt9.png'
import ev10 from '../assets/Gallary/Event/expo1.jpeg'
import ev11 from '../assets/Gallary/Event/expo2.jpeg'
import ev12 from '../assets/Gallary/Event/expo3.jpeg'
import ev13 from '../assets/Gallary/Event/expo4.jpeg'
import ev14 from '../assets/Gallary/Event/expo5.jpeg'
import ev15 from '../assets/Gallary/Event/expo6.jpeg'
import ev16 from '../assets/Gallary/Event/expo7.jpeg'
import ev17 from '../assets/Gallary/Event/expo8.jpeg'
import ev18 from '../assets/Gallary/Event/expo9.jpeg'
import ev19 from '../assets/Gallary/Event/expo10.jpeg'
import ev20 from '../assets/Gallary/Event/jscoe1.png'
import ev21 from '../assets/Gallary/Event/jscoe2.png'




const Event = () => {
    return (
        <>
            <div className='gallary'>
                {/* <h1><span>All About The CSI</span></h1> */}
                <SlideshowLightbox className='container gallaryContainer grid grid-cols-3 gap-2 mx-auto'>
                    <img className='w-full rounded' src={ev1} alt='...' />
                    <img className='w-full rounded' src={ev2} alt='...' />
                    <img className='w-full rounded' src={ev3} alt='...' />
                    <img className='w-full rounded' src={ev4} alt='...' />
                    <img className='w-full rounded' src={ev5} alt='...' />
                    <img className='w-full rounded' src={ev6} alt='...' />
                    <img className='w-full rounded' src={ev7} alt='...' />
                    <img className='w-full rounded' src={ev8} alt='...' />
                    <img className='w-full rounded' src={ev9} alt='...' />
                    <img className='w-full rounded' src={ev10} alt='...' />
                    <img className='w-full rounded' src={ev11} alt='...' />
                    <img className='w-full rounded' src={ev12} alt='...' />
                    <img className='w-full rounded' src={ev13} alt='...' />
                    <img className='w-full rounded' src={ev14} alt='...' />
                    <img className='w-full rounded' src={ev15} alt='...' />
                    <img className='w-full rounded' src={ev16} alt='...' />
                    <img className='w-full rounded' src={ev17} alt='...' />
                    <img className='w-full rounded' src={ev18} alt='...' />
                    <img className='w-full rounded' src={ev19} alt='...' />
                    <img className='w-full rounded' src={ev20} alt='...' />
                    <img className='w-full rounded' src={ev21} alt='...' />
                </SlideshowLightbox>
            </div>
        </>
    )
}

export default Event