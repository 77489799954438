import React,{useEffect} from "react"
import "./Home.css"
import cskrishi from "../assets/cskrishi.png"
import Marquee from 'react-fast-marquee'
import AOS from 'aos';
import 'aos/dist/aos.css';

import cc1 from "../assets/clients and colaboration/jsp.jpeg"
import cc2 from "../assets/clients and colaboration/jspm.jpeg"
import cc3 from "../assets/clients and colaboration/automation.png"
import cc4 from "../assets/clients and colaboration/bhartivid.jpeg"
import cc5 from "../assets/clients and colaboration/bihar2.jpeg"
import cc6 from "../assets/clients and colaboration/bulbkey.jpeg"
import cc7 from "../assets/clients and colaboration/bihargov.png"
import cc8 from "../assets/clients and colaboration/collgofeng.jpeg"
import cc9 from "../assets/clients and colaboration/dpes.jpeg"
import cc10 from "../assets/clients and colaboration/fstc.jpeg"
import cc11 from "../assets/clients and colaboration/gurusys.jpeg"
import cc12 from "../assets/clients and colaboration/indianarmy.jpeg"
import cc13 from "../assets/clients and colaboration/mit.jpeg"
import cc14 from "../assets/clients and colaboration/narhe.jpeg"
import cc15 from "../assets/clients and colaboration/reachnew.jpeg"
import cc16 from "../assets/clients and colaboration/rksons.jpeg"
import cc17 from "../assets/clients and colaboration/sparkonix.jpeg"
import cc18 from "../assets/clients and colaboration/tehri.jpeg"
import cc19 from "../assets/clients and colaboration/visit.jpeg"

import tyh from '../assets/TYH.png'

import vm from '../assets/homeVM.png'

import news1 from "../assets/news/lokmat1.png"
import news2 from "../assets/news/media.png"
import news3 from "../assets/news/Zee_24_taas.png"
import news4 from "../assets/news/tv91.png"
import news5 from "../assets/news/Navbharat_Times.png"
import news6 from "../assets/news/samtv.png"





export default function Home() {
  useEffect(() => {
        AOS.init();
    })
  return (
    <>
      <div className="Home">
        <section className="Herobg">
          <div className="flying_drone">
            {/* <img src= alt=""  /> */}
            <img src={cskrishi} class="img-fluid" alt="..." height={300} width={500}></img>
          </div>
          <div className="HeroContent">
            <h1>Cerebrospark  <span className="inno">Innovations</span></h1>
            <p>A Drone Manufacturer & Drone's Solution Provider Company based in Pune</p>
          </div>
        </section>
        <div className="TYH" data-aos="zoom-in">
          <div className="Tyh1">
            <img src={tyh} class="img-fluid" alt="..." height={60}></img>
          </div>
        </div>


        <div data-aos="zoom" className="container">
          <img src={vm} className="img-fluid " alt="" />
        </div>




        <section className="clientscollabbg">

          <div className="clientscollab">
            <div className="title1" data-aos="zoom-in">
              <h1>Our Clients & Collaboration</h1>
            </div>
            <div>
              <Marquee direction="right" speed={50} delay={1}>
                <div className="image_wrapper">
                  <img src={cc13} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc2} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc3} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc4} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc5} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc6} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc7} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc8} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc17} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc10} alt="" />
                </div>
              </Marquee>
              <Marquee direction="left" speed={50} delay={1}>
                <div className="image_wrapper">
                  <img src={cc11} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc12} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc1} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc14} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc15} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc16} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc9} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc18} alt="" />
                </div>
                <div className="image_wrapper">
                  <img src={cc19} alt="" />
                </div>
              </Marquee>
            </div>
          </div>
        </section>

        <section className="news">
          <div className="title1" data-aos="zoom-in">
            <h1>Featured In</h1>
          </div>
          <div class="row1">
            <div class="nscolumn" data-aos="fade-down">
              <figure class="figure">
              <a href="https://youtu.be/1zddFVlVq7Y?si=i2xUMYp6PJ3gectw">
                <img src={news1} class="figure-img img-fluid rounded" alt="..." />
              </a>
                <figcaption class="figure-caption">Team Cerebrospark on Lokmat News.</figcaption>
              </figure>
            </div>

            <div class="nscolumn" data-aos="fade-down">
            <figure class="figure">
              <a href="https://youtu.be/2YysPRZ_8NQ?si=Bkmh6JZ-DuD1LMot">
                <img src={news3} class="figure-img img-fluid rounded" alt="..." />
              </a>
                <figcaption class="figure-caption">Team Cerebrospark on Zee24 taas News.</figcaption>
              </figure>
            </div>



            <div class="nscolumn" data-aos="fade-down">
            <figure class="figure">
              <a href="https://youtu.be/KlWPtmK3tbA?si=6BIM-nQOyeBttGWC">
                <img src={news5} class="figure-img img-fluid rounded" alt="..." />
              </a>
                <figcaption class="figure-caption">Team Cerebrospark on NBT News.</figcaption>
              </figure>
            </div>

            <div class="nscolumn" data-aos="fade-down">
            <figure class="figure">
              <a href="https://youtu.be/WXcyDnz5nIE?si=50ofNtbl7Yzf-uLV">
                <img src={news6} class="figure-img img-fluid rounded" alt="..." />
              </a>
                <figcaption class="figure-caption">Team Cerebrospark on SamTv News.</figcaption>
              </figure>
            </div>
            <div class="nscolumn" data-aos="fade-down">
            <figure class="figure">
              <a href="https://youtu.be/WXcyDnz5nIE?si=50ofNtbl7Yzf-uLV">
              </a>
              <a href="https://youtu.be/96Tx5k5V9g0?si=0gtrmSY2tr2OtOY6">
                <img src={news4} class="figure-img img-fluid rounded" alt="..." />
              </a>
                <figcaption class="figure-caption">Team Cerebrospark on Tv9 News.</figcaption>
              </figure>
            </div>
            <div class="nscolumn" data-aos="fade-down">
            <figure class="figure">
              <a href="https://youtu.be/ec-JPLJPJ9g?si=RFcwdceAaGbWN5S_">
                <img src={news2} class="figure-img img-fluid rounded" alt="..." />
              </a>
                <figcaption class="figure-caption">Hon. Piyush Goyal Sir</figcaption>
              </figure>
            </div>
          </div>



        </section>
      </div>
    </>
  )
}
