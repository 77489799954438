import React from 'react'
import { SlideshowLightbox } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import './Gallary.css'

import awd1 from '../assets/Gallary/Awards_recognition/ch1.png'
import awd2 from '../assets/Gallary/Awards_recognition/ch2.png'
import awd3 from '../assets/Gallary/Awards_recognition/fs1.png'
import awd4 from '../assets/Gallary/Awards_recognition/fs2.png'
import awd5 from '../assets/Gallary/Awards_recognition/G20.png'
import awd6 from '../assets/Gallary/Awards_recognition/ju1.jpeg'
import awd7 from '../assets/Gallary/Awards_recognition/ju2.jpeg'
import awd8 from '../assets/Gallary/Awards_recognition/ju3.jpeg'
import awd9 from '../assets/Gallary/Awards_recognition/ts1.png'

const Awards_reco = () => {
  return (
    <>
      <div className='gallary'>
                {/* <h1><span>All About The CSI</span></h1> */}
                <SlideshowLightbox className='container gallaryContainer grid grid-cols-3 gap-2 mx-auto'>
                    <img className='w-full rounded' src={awd1} alt='...' />
                    <img className='w-full rounded' src={awd2} alt='...' />
                    <img className='w-full rounded' src={awd3} alt='...' />
                    <img className='w-full rounded' src={awd4} alt='...' />
                    <img className='w-full rounded' src={awd5} alt='...' />
                    <img className='w-full rounded' src={awd6} alt='...' />
                    <img className='w-full rounded' src={awd7} alt='...' />
                    <img className='w-full rounded' src={awd8} alt='...' />
                    <img className='w-full rounded' src={awd9} alt='...' />
                    
                </SlideshowLightbox>
            </div>
    </>
  )
}

export default Awards_reco
