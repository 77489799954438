import React from 'react'
import { SlideshowLightbox } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import './Gallary.css'

import colb1 from '../assets/Gallary/Collaboration/colb1.png'
import colb2 from '../assets/Gallary/Collaboration/colb2.png'
import colb3 from '../assets/Gallary/Collaboration/colb3.jpg'
import colb4 from '../assets/Gallary/Collaboration/colb4.png'

const Collaboration = () => {
  return (
    <>
      <div className='gallary'>
                {/* <h1><span>All About The CSI</span></h1> */}
                <SlideshowLightbox className='container gallaryContainer grid grid-cols-3 gap-2 mx-auto'>
                    <img className='w-full rounded' src={colb1} alt='...' />
                    <img className='w-full rounded' src={colb2} alt='...' />
                    <img className='w-full rounded' src={colb3} alt='...' />
                    <img className='w-full rounded' src={colb4} alt='...' />
                    
                </SlideshowLightbox>
            </div>
    </>
  )
}

export default Collaboration
