import React from 'react'
import { SlideshowLightbox } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import './Gallary.css'

import wrk1 from '../assets/Gallary/Workshop/1.png'
import wrk2 from '../assets/Gallary/Workshop/2.png'
import wrk3 from '../assets/Gallary/Workshop/3.png'
import wrk4 from '../assets/Gallary/Workshop/4.png'
import wrk5 from '../assets/Gallary/Workshop/5.png'
import wrk6 from '../assets/Gallary/Workshop/6.png'
import wrk7 from '../assets/Gallary/Workshop/7.jpg'
import wrk8 from '../assets/Gallary/Workshop/8.jpg'
import wrk9 from '../assets/Gallary/Workshop/9.jpg'
import wrk10 from '../assets/Gallary/Workshop/10.jpg'
import wrk11 from '../assets/Gallary/Workshop/11.jpg'
import wrk12 from '../assets/Gallary/Workshop/12.jpg'


import infr1 from '../assets/Gallary/Infrastructure/1.png'
import infr2 from '../assets/Gallary/Infrastructure/2.png'

const Workshop = () => {
  return (
    <>
      <div className='gallary'>
                {/* <h1><span>All About The CSI</span></h1> */}
                <SlideshowLightbox className='container gallaryContainer grid grid-cols-3 gap-2 mx-auto'>
                    <img className='w-full rounded' src={wrk1} alt='...' />
                    <img className='w-full rounded' src={wrk2} alt='...' />
                    <img className='w-full rounded' src={wrk3} alt='...' />
                    <img className='w-full rounded' src={wrk4} alt='...' />
                    <img className='w-full rounded' src={wrk5} alt='...' />
                    <img className='w-full rounded' src={wrk6} alt='...' />
                    <img className='w-full rounded' src={wrk7} alt='...' />
                    <img className='w-full rounded' src={wrk8} alt='...' />
                    <img className='w-full rounded' src={wrk9} alt='...' />
                    <img className='w-full rounded' src={wrk10} alt='...' />
                    <img className='w-full rounded' src={wrk11} alt='...' />
                    <img className='w-full rounded' src={wrk12} alt='...' />
                    <img className='w-full rounded' src={infr1} alt='...' />
                    <img className='w-full rounded' src={infr2} alt='...' />
                    
                </SlideshowLightbox>
            </div>
    </>
  )
}

export default Workshop
