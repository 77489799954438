import React from 'react'
import './Sparkathon.css'
import SparkathonBG from '../assets/SparkathonBG.png'
import psDrone from '../assets/psDrone.png'
import robotics from '../assets/robotics.png'
import AgriTech from '../assets/AgriTech.png'
import OpenCata1 from '../assets/OpenCata1.png'
import prize1 from '../assets/prize1.png'
import prize2 from '../assets/prize2.png'
import prize3 from '../assets/prize3.png'
import flow from '../assets/Flow.png'

import judge1 from '../assets/judge1.png'
import judge2 from '../assets/judge2.png'
import judge3 from '../assets/judge3.jpg'
import judge4 from '../assets/judge4.png'
import judge5 from '../assets/judge5.jpg'

import client1 from '../assets/clients/client1.png'
import client2 from '../assets/clients/client2.png'
import client3 from '../assets/clients/client3.png'
import client4 from '../assets/clients/client4.png'
import client5 from '../assets/clients/client5.png'
import client6 from '../assets/clients/client6.png'
import client7 from '../assets/clients/client7.png'
import client8 from '../assets/clients/client8.png'
import client9 from '../assets/clients/client9.png'
import client10 from '../assets/clients/client10.png'
import client11 from '../assets/clients/client11.png'
import client12 from '../assets/clients/client12.png'
import client13 from '../assets/clients/client13.png'
import client14 from '../assets/clients/client14.png'
import client15 from '../assets/clients/client15.jpg'
import client16 from '../assets/clients/client16.png'
const Sparkathon = () => {
  return (
    <>
      <div className='spark'>
        <img src={SparkathonBG} alt='...' />
        <h1 className='CHeading'>  About Sparkathon </h1>
        <p className='para'>Sparkathon is a National level 24 hour hackathon to bring out engineers, innovators and budding entrepreneurs together for a faceoff. More than 4000 participants and 1000 teams will work on various problem statements under 4 different categories. Sparkathon is an initiative by Cerebrospark Innovations in association with JSPM's Jayawantrao Sawant College Of Engineering on the auspicious occasion of 'Azadi Ka Amrut Mahotsav'.
          Sparkathon will help students & innovators towards entrepreneurship by providing them industrial & academic mentoring, incubation support and it will act as a catalyst for their journey from ideation to start-up.</p>
        <h1 className='CHeading'>  Problem Statements </h1>
        <div className='PSmaincontainer'>
          <div className='PScontainer'>
            <img src={psDrone} alt='...' />
            <div className='PSdata'>
              <h1>Drones</h1>
              <h3>Innovations or development in Drone, it’s applications.</h3>
              <p>Last-mile delivery, Handling Medical Equipment's, Search and Rescue Operations, AI in Drones, Landslide Monitoring, Tethered UAVs, Swarms of UAVs, Drones for Defence, Drones in Agriculture, Object Detection, Intelligent Tracking System, VR based Drone Flying Simulator, Non-Invasive Autopilot System, Scanning Technology Advancement using Drones, Drone Countermeasures, RFID Applications using Drones, Advance Transmitter and Receiver, etc.</p>
            </div>
          </div>
        </div>
        <div className='PSmaincontainer'>
          <div className='PScontainer'>
            <img src={robotics} alt='...' />
            <div className='PSdata'>
              <h1>Robotics</h1>
              <h3>Innovations or development in Robotics, it’s applications.</h3>
              <p>Robotic clamp for Asymmetrical object clamping, Robotic arm for Industrial Cleaning, Human- Computer Interfaces, Emergency response technology, ROV for Defence, Artificial Intelligence and Machine Learning System in Robotics, Advance Gimbal System, Autonomous Robots, IoT Applications, Mobile Robots, etc.</p>
            </div>
          </div>
        </div>
        <div className='PSmaincontainer'>
          <div className='PScontainer'>
            <img src={AgriTech} alt='...' />
            <div className='PSdata'>
              <h1>Agri-Tech</h1>
              <h3>Innovations or development in Agri-Tech, it’s applications.</h3>
              <p>Assessment of Land Holding Pattern, Irrigation Technology, Auto Climate Monitoring System, UAVs technology in agriculture, IOT Applications for Crop Monitoring and Surface Irrigation, Mobile app for Crop Management, Real Time Estimation of Crop Yield technique , Smart Farming, Applications in Agriculture, etc.</p>
            </div>
          </div>
        </div>
        <div className='PSmaincontainer'>
          <div className='PScontainer'>
            <img src={OpenCata1} alt='...' />
            <div className='PSdata'>
              <h1>Open Category</h1>
              <h3>Open category for student’s innovation.</h3>
              <p>We are thrilled to invite you to participate in Sparkathon, where innovation knows no bounds! Our Open Category is calling for projects that break the mold and redefine the future.
                Choose your project, explore your passion and
                tackle real-world challenges with ingenious solutions.
                Showcase your skills in a diverse range of fields.
                Collaborate, learn, and be part of a groundbreaking event.</p>
            </div>
          </div>
        </div>

        <div className='RegData'>
          <div className='Regdata1'>
            <h1>Don't have a project idea but want to register?</h1>
            <h2>Yes! You can do it NOW.</h2>
          </div>
          <div className='Regdata2'>
            Why you need to register yourself if you don't have project idea?<br />

            If in any round, two teams will get the same score, Then additional points will be given to the team who have registered first.<br />

            Just fill in the basic details and confirm your registration!
            And fill in the project details later on, before 31st Dec 2021.<br />
            After filling basic details you will get a mail within 24 hours, where you will get the link of second form containing project submission details.<br />
            If you have project details, then click here to directly register.
          </div>
        </div>
        <h1 className='CHeading'>  Top Prizes </h1>
        <div className='prizes'>
          <div className='prize1'>
            <img src={prize1} alt='...' />
            <h2>Prize1</h2>
          </div>
          <div className='prize1'><img src={prize2} alt='...' />
            <h2>Prize2</h2></div>
          <div className='prize1'><img src={prize3} alt='...' />
            <h2>Prize3</h2></div>
        </div>
        <h1 className='CHeading'>  Other Prizes </h1>
        <div className='OtherPrizes'>
          <div className='OtherContainer'>
            {/* <div className='Otherbox'></div> */}
            <div className='Other1'>
              <h3>Best Performer in Each Domain</h3>
              <p>The participants who will show their best performance in each evaluation sector from their domain will be awarded with this prize.</p>
            </div>
            <div className='Other1'>
              <h3>Popular College Award</h3>
              <p>The college who will have the most registered participants will be awarded with this prize.</p>
            </div>
          </div>
          <div className='OtherContainer'>
            {/* <div className='Otherbox'></div> */}
            <div className='Other1'>
              <h3>Best Women Team</h3>
              <p>The team with all women participants will be considered for this & the best performing women team according to the evaluation will be given this prize.</p>
            </div>
            <div className='Other1'>
              <h3>Most Disciplined Team</h3>
              <p>The team with good discipline, co-ordination & team work will be awarded with this prize.</p>
            </div>
          </div>
          <div className='OtherContainer'>
            {/* <div className='Otherbox'></div> */}
            <div className='Other1'>
              <h3>Best Presentation Award</h3>
              <p>This award will be given to the team who will be best at presenting themselves and their project with precision.</p>
            </div>
            <div className='Other1'>
              <h3>Goodies & Other Prizes</h3>
              <p>All the final round participants will get goodies & many more. All the participants will get participation certificates.</p>
            </div>
          </div>

        </div>
        <div className='Sparkathon'>
          {/* <img src={flow} alt='...' /> */}
          <img src={flow} class="img-fluid" alt="..."></img>
        </div>
        <h1 className='CHeading'>Jugdes</h1>
        <div className='Judges'>
          <div className='JudgesContainer'>
            <div className='Judgedata'>
              <img src={judge1} alt='...' />
              <div className='jddiv'>
                <h3>Mr. Prashant Joglekar</h3>
                <p>Director, Defence & Electronics at MCCIA.</p>
              </div>
            </div>
            <div className='Judgedata'>
              <img src={judge2} alt='...' />
              <div className='jddiv'>
                <h3>Dr. Rajendra Kanphade</h3>
                <p>Principal, JSPM's Jayawantaro Sawant College Of Engineering.</p>
              </div>
            </div>
          </div>
          <div className='JudgesContainer'>
            <div className='Judgedata'>
              <img src={judge3} alt='...' />
              <div className='jddiv'>
                <h3>Dr. Mohit Dubey</h3>
                <p>CEO, AIC-MIT-ADT Incubator Forum.</p>
              </div>
            </div>
            <div className='Judgedata'>
              <img src={judge4} alt='...' />
              <div className='jddiv'>
                <h3>Mr. Pankaj Sharma</h3>
                <p>Head, Makers Lab- Tech Mahindra.</p>
              </div>
            </div>
          </div>
          <div className='JudgesContainer'>
            <div className='Judgedata'>
              <img src={judge5} alt='...' />
              <div className='jddiv'>
                <h3>Mr. Mandar Joshi</h3>
                <p>Principal, JSPM's Jayawantaro Sawant College Of Engineering.</p>
              </div>
            </div>
          </div>
        </div>
        <h1 className='CHeading'> Technical Partners </h1>
        <div className='TechPart'>
          <img src={client1} alt='...' />
          <img src={client2} alt='...' />
          <img src={client3} alt='...' />
          <img src={client4} alt='...' />
          <img src={client5} alt='...' />
          <img src={client6} alt='...' />
          <img src={client7} alt='...' />
          <img src={client8} alt='...' />
          <img src={client9} alt='...' />
          <img src={client10} alt='...' />
          <img src={client11} alt='...' />
          <img src={client12} alt='...' />
          <img src={client13} alt='...' />
          <img src={client14} alt='...' />
          <img src={client15} alt='...' />
          <img src={client16} alt='...' />

        </div>
      </div>
    </>
  )
}

export default Sparkathon