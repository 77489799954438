// import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { SlideshowLightbox } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import './Gallary.css'

// import Gbg from '../assets/Gallery_bg.jpg'

import ev1 from '../assets/Gallary/Event/evnt5.png'
import ev2 from '../assets/Gallary/Event/evnt2.png'
import ev3 from '../assets/Gallary/Event/expo10.jpeg'
import ev4 from '../assets/Gallary/Event/evnt4.png'

import act1 from '../assets/Gallary/Activities/kj1.jpeg'
// import act2 from '../assets/Gallary/Activities/kj2.png'
import act3 from '../assets/Gallary/Activities/kj3.png'
import act23 from '../assets/Gallary/Activities/sky1.png'
import onf10 from '../assets/Gallary/Onfield/10.png'

import wrk1 from '../assets/Gallary/Workshop/1.png'
import wrk2 from '../assets/Gallary/Workshop/2.png'
import wrk3 from '../assets/Gallary/Workshop/3.png'
import wrk4 from '../assets/Gallary/Workshop/7.jpg'


import trn1 from '../assets/Gallary/Training/20.jpg'
import trn2 from '../assets/Gallary/Training/2.jpg'
import trn3 from '../assets/Gallary/Training/13.jpg'
import trn4 from '../assets/Gallary/Training/14.jpg'

import meet1 from '../assets/Gallary/Meetup/1.png'
import meet2 from '../assets/Gallary/Meetup/2.png'
import meet3 from '../assets/Gallary/Meetup/3.png'
import meet4 from '../assets/Gallary/Meetup/4.png'

import colb1 from '../assets/Gallary/Collaboration/colb1.png'
import colb2 from '../assets/Gallary/Collaboration/colb2.png'
import colb3 from '../assets/Gallary/Collaboration/colb3.jpg'
import colb4 from '../assets/Gallary/Collaboration/colb4.png'

import awd1 from '../assets/Gallary/Awards_recognition/ch1.png'
import awd2 from '../assets/Gallary/Awards_recognition/G20.png'
import awd3 from '../assets/Gallary/Awards_recognition/fs1.png'
import awd4 from '../assets/Gallary/Awards_recognition/ts1.png'



const Gallary = () => {
    return (
        <>
            <div className='gallary'>
                <div className='Event_G'>
                    <h1><span>Events</span></h1>
                    <SlideshowLightbox className='container gallaryContainer grid grid-cols-3 gap-2 mx-auto'>
                        <img className='w-full rounded' src={ev1} alt='...' />
                        <img className='w-full rounded' src={ev2} alt='...' />
                        <img className='w-full rounded' src={ev3} alt='...' />
                        <img className='w-full rounded' src={ev4} alt='...' />
                    </SlideshowLightbox>
                    <div className='see_more_G'>
                    <Link to="/event1"><button type="button" class="btn btn-primary">See More</button></Link>
                    </div>
                </div>
                <div className='Event_G'>
                    <h1><span>Activities</span></h1>
                    <SlideshowLightbox className='container gallaryContainer grid grid-cols-3 gap-2 mx-auto'>
                        <img className='w-full rounded' src={act1} alt='...' />
                        <img className='w-full rounded' src={onf10} alt='...' />
                        <img className='w-full rounded' src={act3} alt='...' />
                        <img className='w-full rounded' src={act23} alt='...' />
                    </SlideshowLightbox>
                    <div className='see_more_G'>
                    <Link to="/activities"><button type="button" class="btn btn-primary">See More</button></Link>
                    </div>
                </div>
                <div className='Event_G'>
                    <h1><span>Workshops</span></h1>
                    <SlideshowLightbox className='container gallaryContainer grid grid-cols-3 gap-2 mx-auto'>
                        <img className='w-full rounded' src={wrk1} alt='...' />
                        <img className='w-full rounded' src={wrk2} alt='...' />
                        <img className='w-full rounded' src={wrk3} alt='...' />
                        <img className='w-full rounded' src={wrk4} alt='...' />
                    </SlideshowLightbox>
                    <div className='see_more_G'>
                    <Link to="/workshop"><button type="button" class="btn btn-primary">See More</button></Link>
                    </div>
                </div>
                <div className='Event_G'>
                    <h1><span>Trainings</span></h1>
                    <SlideshowLightbox className='container gallaryContainer grid grid-cols-3 gap-2 mx-auto'>
                        <img className='w-full rounded' src={trn1} alt='...' />
                        <img className='w-full rounded' src={trn2} alt='...' />
                        <img className='w-full rounded' src={trn3} alt='...' />
                        <img className='w-full rounded' src={trn4} alt='...' />
                    </SlideshowLightbox>
                    <div className='see_more_G'>
                    <Link to="/training"><button type="button" class="btn btn-primary">See More</button></Link>
                    </div>
                </div>
                <div className='Event_G'>
                    <h1><span>Meetups</span></h1>
                    <SlideshowLightbox className='container gallaryContainer grid grid-cols-3 gap-2 mx-auto'>
                        <img className='w-full rounded' src={meet1} alt='...' />
                        <img className='w-full rounded' src={meet2} alt='...' />
                        <img className='w-full rounded' src={meet3} alt='...' />
                        <img className='w-full rounded' src={meet4} alt='...' />
                    </SlideshowLightbox>
                    <div className='see_more_G'>
                    <Link to="/meetup"><button type="button" class="btn btn-primary">See More</button></Link>
                    </div>
                </div>
                <div className='Event_G'>
                    <h1><span>Collaborations</span></h1>
                    <SlideshowLightbox className='container gallaryContainer grid grid-cols-3 gap-2 mx-auto'>
                        <img className='w-full rounded' src={colb1} alt='...' />
                        <img className='w-full rounded' src={colb2} alt='...' />
                        <img className='w-full rounded' src={colb3} alt='...' />
                        <img className='w-full rounded' src={colb4} alt='...' />
                    </SlideshowLightbox>
                    <div className='see_more_G'>
                    <Link to="/collaboration"><button type="button" class="btn btn-primary">See More</button></Link>
                    </div>
                </div>
                <div className='Event_G'>
                    <h1><span>Awards & Recognitions</span></h1>
                    <SlideshowLightbox className='container gallaryContainer grid grid-cols-3 gap-2 mx-auto'>
                        <img className='w-full rounded' src={awd1} alt='...' />
                        <img className='w-full rounded' src={awd2} alt='...' />
                        <img className='w-full rounded' src={awd3} alt='...' />
                        <img className='w-full rounded' src={awd4} alt='...' />
                    </SlideshowLightbox>
                    <div className='see_more_G'>
                    <Link to="/awd_rec"><button type="button" class="btn btn-primary">See More</button></Link>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Gallary
