import React from 'react'
import './Course2.css'


import logo1 from '../assets/course21d/ICARlogo.png'
import logo2 from '../assets/course21d/NAHEPLogo.png'
import logo3 from '../assets/course21d/VNMKV logo.png'
import logo4 from '../assets/Csilogo.png'


import img1 from '../assets/rpto/circle1.png'


const Course2 = () => {
  return (
    <>
      <div className="container p-3 my-2">
        <div className='course21d-logo'>
          <img src={logo3} class="img-fluid" alt="..." />
          <img src={logo1} class="img-fluid" alt="..." />
          <img src={logo2} class="img-fluid" alt="..." />
          <img src={logo4} class="img-fluid" alt="..." />
        </div>

        <div className='text-center'>
          <h5 >Vasantrao Naik Marathwada krishi Vidyapeeth, Parbhani with Cerebrospark Innovations, Pune Presents</h5>
          <h1>21 Days</h1>
          <h2>‘PROFESSIONAL CERTIFICATION COURSE ON DRONES’ </h2>
        </div>

        <div className="c21-highlights my-5">
          <h1 className='heading-c21'>Key Highlights of Course</h1>
          <div className='d-flex justify-content-center c21-highlights-sub'>
            <div className='m-1 d-flex justify-content-center flex-column'>
              <div className='highlights-div p-3 m-3 border border-1 border-black rounded-3'>
                <i class="text-primary bi bi-award"></i>
                <b>DGCA Pilot Certification:</b><br />
                Maintenance of Drones
                Standard Operating Procedures
                Become a DGCA certified drone pilot and build
                your career in drone industry.
              </div>
              <div className='highlights-div p-3 m-3 border border-1 border-black rounded-3'>
                <i class="text-primary bi bi-gear"></i>
                <b>Maintenance of Drones</b><br />
                Students will acquire comprehensive skills in
                troubleshooting, repairing, and optimizing drone
                systems to ensure peak performance and
                reliability in aerial operations.
              </div>
              <div className='highlights-div p-3 m-3 border border-1 border-black rounded-3'>
                <i class="text-primary bi bi-patch-check"></i>
                <b>Standard Operating Procedures</b><br />
                Students learn to develop and implement effective
                protocols for safe and compliant drone operations.

              </div>
            </div>
            <div className='m-1 d-flex justify-content-center flex-column'>
              <div className='highlights-div p-3  m-3 border border-1 border-black rounded-3'>
                <i class="text-primary bi bi-award"></i>
                <b>ICAR Approved VNMKV & CSI Certification</b><br />
                This course will provide you the ICAR approved
                VNMKV and Cerebrospark recognized certificate.

              </div>
              <div className='highlights-div p-3  m-3 border border-1 border-black rounded-3'>
                <i class="text-primary bi bi-robot"></i>
                <b>Handling of Drones</b><br />
                Students will master the principles of safe and
                efficient drone operation, learning essential skills
                such as pre-flight checks, flight planning, and
                maneuvering techniques.
              </div>
              <div className='highlights-div p-3 m-3 border border-1 border-black rounded-3'>
                <i class="text-primary bi bi-card-checklist"></i>
                <b>Rules & Regulations</b><br />
                Students will gain a comprehensive understanding
                of the legal frameworks governing drone
                operations, including airspace restrictions,
                registration requirements, and privacy laws.
              </div>
            </div>
          </div>
          <div className='c21-fees p-3 text-center border border-1 border-black rounded-3'>
            <i class="text-primary bi bi-wallet2"></i>
            <b>Course Fees: </b> Rs. 50,000/- <br />
            <b>Registration Fees: </b> Rs. 2,500/-
          </div>
        </div>

        <h5 className='bg-primary-subtle py-3 my-4  text-center'><button className='btn btn-primary text-white'><a href="https://forms.gle/rH7J8191W7S3cjNQA" className='text-white'>Register Now</a></button></h5>

        <div className="batchDate d-flex justify-content-center ">
          <div className='batchDate-div text-center  position-relative'>
            <img src={img1} alt="..." height={250} />
            <h4 className='text-success'><b> Batch <br />Started on <br />1st July 2024.</b></h4>
          </div>
          <div className='contact-div  d-flex flex-column justify-content-center align-items-center'>
            <h1>Contact</h1>
            <div className='border border-1 border-black rounded-5 p-3 align-items-center'>
            <i class="text-primary bi bi-geo-alt"></i> NAHEP Centre, VNMKV, Parbhani 431402  <br />
            <i class="text-primary bi bi-envelope"></i> cerebrosparkacademy@gmail.com <br />
            <i class="text-primary bi bi-globe"></i> nahep.vnmkv.org.in  <br />
            <i class="text-primary bi bi-telephone"></i>+91 7499884991/ 9028970738 

            </div>
          </div>

        </div>


      </div>

    </>
  )
}

export default Course2
