import React from 'react'
import "./ContactUs.css"
// import emailjs from '@emailjs/browser';

import conlogo from '../assets/conlogo.png'

const ContactUs = () => {
  
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "88a53452-dbb1-493d-b2ce-35c40527fa67");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <>
      <div className="ContactUs my-4">
        <div className='CompHead'>
          <img src={conlogo} alt='...' height={70} /><br></br>
          <h3 style={{fontFamily:" 'Times New Roman', Times, sans-serif"}}>Cerebrospark Innovations</h3>
          <h5>A drone manufacturer and drone solutions provider!</h5>
        </div>
        <div className='contbody'>
          <div className='cont1'>
            <div className="Cformcontainer">
              <form onSubmit={onSubmit}>
                <input type="hidden" name="access_key" value="88a53452-dbb1-493d-b2ce-35c40527fa67"></input>
                <input type="text" name="name" required placeholder='Name' />
                <input type="email" name="email" required placeholder='Email' />
                <input type="number" name="mobile" required placeholder='Mobile No.' />
                <input type="text" name="requirement" required placeholder='Product Requirement: CS-KRISHI,
 MAMBA,
 BEE,
 VTOL,
 PRIDE,
 PAVANPUTRA'/>
                
                <textarea name="message" required placeholder='Message'></textarea>

                <button type="submit" className='Con_button'>Submit</button>

              </form>
              <span>{result}</span>
            </div>
          </div>
          <div className='cont1'>
            <div className="textcontainer" >
              {/* <h1 >Cerebrospark Innovations</h1> */}
              <div className="ContactUs_item">
                <h5>Mail</h5>
                <span>info@cerebrospark.in</span>
              </div><br/>
              <div className="ContactUs_item">
                <h5>Address</h5>
                <a href='https://maps.app.goo.gl/7po8kcAHo9diYuRU9'><span> Sr. No. 55, Plot No 14, Sai Niwas Society, Pune, Maharashtra, India 411060</span></a>

              </div><br/>
              <div className="ContactUs_item">
                <h5>Phone</h5>
                <span>+91 8600104934 / 7387515448</span>
              </div>
            </div>
          </div>
        </div>
      </div>




    </>
  )
}

export default ContactUs