import React,{useEffect} from 'react'
import './Services.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

import serv1 from '../assets/services/csk.png'
// import serv2 from '../assets/services/disaster.png'
import serv2 from '../assets/Applications/disaster managment.jpeg'
import serv3 from '../assets/services/survillence.png'
// import serv4 from '../assets/services/mapping.png'
import serv5 from '../assets/services/delivery.png'
import serv7 from '../assets/services/inspection.png'
import serv8 from '../assets/services/solar.png'

import serv4 from '../assets/Applications/mapping.png'
const Services = () => {
    useEffect(() => {
        AOS.init();
    })
    return (
        <>
            <div className='CS_service_main my-4' data-aos="fade-up">
                <h1>SERVICES</h1>
                <div>
                    <div className='service1' data-aos="fade-right">
                        <div className='servedata1'>
                            <img src={serv1} class="img-fluid" data-aos="zoom-in" alt="..." height={250} />
                        </div>
                        <div className='servedata'>
                            <h1>AGRICULTURE</h1>
                            <p>
                                Drones can be used in agriculture for crop monitoring, disease identification and spraying operations. Drones are equipped with high resolution cameras which collect data accurately and efficiently. Using drone technology can significantly enhance agricultural productivity and efficiency. Drones enable the mapping of farm fields for better crop pattern planning and irrigation management. Multispectral mapping is utilized to monitor crop health, while also gathering essential data for insurance claims. Additionally, digital field boundaries can be accurately mapped and linked to cadastral maps. Advanced AI models provide precise crop monitoring and analysis, including features for monitoring crop height, growth, and tree counting. Cerebrospark Innovations offers state-of-the-art Agricultural Drones equipped with a spraying mechanism, integrated RGB + Thermal Multispectral Cameras, and a seed dropping mechanism.</p>
                        </div>

                    </div>
                    <div className='service2' data-aos="fade-left">
                        <div className='servedata1'>
                            <img src={serv2} class="img-fluid" data-aos="zoom-in" alt="..." height={250} />
                        </div>
                        <div className='servedata'>
                            <h1>DISASTER MANAGEMENT</h1>
                            <p>
                                Drones play a pivotal role in disaster management by offering swift and efficient assistance. These aerial devices aid in assessing damage, surveying affected areas, and locating survivors. Equipped with cameras and sensors, drones capture high-resolution images and real-time data, enabling responders to make informed decisions. They navigate inaccessible or hazardous terrains, providing crucial information for search and rescue operations, damage assessment, and even delivering essential supplies to areas that are difficult to reach. Their versatility, speed, and ability to cover vast areas make drones invaluable tools in disaster relief efforts.</p>
                        </div>


                    </div>
                </div>
                <div>
                    <div className='service1' data-aos="fade-right">
                        <div className='servedata1'>
                            <img src={serv3} class="img-fluid" data-aos="zoom-in" alt="..." height={250} />
                        </div>
                        <div className='servedata'>
                            <h1>DRONE SURVEILLANCE</h1>
                            <p>
                                Cerebrospark Innovations provide surveillance drones which are equipped with the high resolution cameras & sensors. They are compatible for various terrain conditions and provide highly accurate data for various applications. The advance cameras and sensors make a unique interpretation of data collection in various applications.
                                Drones which we offer are key performers in the various sectors including security operations, Highway management, power plant and dam & bridge inspection, Constructions, etc.
                                Drones come with bird eye view which covers wide area with high resolution of data, also are deployed in minutes, which makes the operations faster. Drones equipped with various sensors and Day-Night cameras come with RTK module for accurate positioning.</p>
                        </div>

                    </div>
                    <div className='service2' data-aos="fade-left">
                        <div className='servedata1'>
                            <img src={serv4} class="img-fluid" data-aos="zoom-in" alt="..." height={250} />
                        </div>
                        <div className='servedata'>
                            <h1>AERIAL MAPPING</h1>
                            <p>
                                The advanced drone with built-in sensors makes mapping operations more efficient and reliable for various industries majorly focusing on Oil & Gas, Mining, Power Plants, Road transport, Coal, etc.
                                Integrated PPK and RTK modules achieve positioning efficiency. Drones can fly at various altitudes for the collection of ground data samplings.
                                With the advanced post-processing software, hundreds of square kilometers of data can be processed with great efficiency.
                                We produce highly precise maps & 3-D models of complex structures. This will reduce the risk to humans in dangerous situations & achieve faster & accurate results.</p>
                        </div>


                    </div>
                </div>
                <div>
                    <div className='service1' data-aos="fade-right">
                        <div className='servedata1'>
                            <img src={serv5} class="img-fluid" data-aos="zoom-in" alt="..." height={250} />
                        </div>
                        <div className='servedata'>
                            <h1>DELIVERY DRONE</h1>
                            <p>
                                Heavy duty payload carrying drone comes with wide range of payload option. Cerebrospark Innovations has various designs in multirotors, fixed wing & VTOL which makes delivery operations wider in terms of payload & Range.
                                These drones are designed mainly for delivery & logistic operations to transport various products, medical supplies, and other essentials. They are equipped with Day & Night cameras, which enables the system to work twenty-four-seven.
                                Installation of various payloads can be completed within a couple of minutes to expedite delivery. Drones are equipped with both manual and autonomous mission capabilities for addressing various critical situations in disaster management, rescue operations, and other calamities.</p>
                        </div>

                    </div>
                </div>
                <div>
                    <div className='service2' data-aos="fade-left">
                        <div className='servedata1'>
                            <img src={serv7} class="img-fluid" data-aos="zoom-in" alt="..." height={250} />
                        </div>
                        <div className='servedata'>
                            <h1>POWERLINE INSPECTION</h1>
                            <p>
                                Power line inspection poses a major challenge in both urban and rural areas, consuming significant time and energy. Drones equipped with high-resolution RGB + thermal cameras make power line inspection more accurate, efficient, and less time-consuming. Cerebrospark Innovations offers unique AI-integrated drones specifically designed for power line inspection, reducing post-processing work by seamlessly integrating an auto-fault detection AI module. This AI module operates simultaneously during drone flights, detecting errors and enhancing inspection operations. High endurance, integrated AI modules, advanced RGB and thermal cameras, along with various sensors, make Cerebrospark Innovations the preferred choice for clients. This technology reduces costs and time for power line vendors.</p>
                        </div>

                    </div>
                    <div className='service1' data-aos="fade-right">
                        <div className='servedata1'>
                            <img src={serv8} class="img-fluid" data-aos="zoom-in" alt="..." height={250} />
                        </div>
                        <div className='servedata'>
                            <h1>SOLAR PANEL INSPECTION</h1>
                            <p>In large or difficult-to-reach areas of solar panel installation, drones offer a useful and secure addition to ground inspections of photovoltaic (PV) installations. Drones are utilized for routine evaluations or to keep an eye on the construction of solar sites. Early detection of degradation, damage, and other anomalies are made possible by the combination of infrared photography with artificial intelligence (AI) algorithms.</p>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default Services