import React from 'react'
import { Link } from 'react-router-dom';
import './products.css'

import { GiShieldReflect } from "react-icons/gi";
import { IoCamera } from "react-icons/io5";
import { GiWeight } from "react-icons/gi";
import { GiDeliveryDrone } from "react-icons/gi";

import pr1 from '../assets/products/pride.png'
// import f1 from '../assets/services/csk.png'

import apppr1 from '../assets/Applications/photography.jpg'
import apppr2 from '../assets/Applications/secu_serv.jpg'

const CS_pride = () => {
  return (
    <>
        <div className='CS_promain'>
                <div className='CS_proHeropage'>
                <div className='border'>
                    <div className='CS_proHeroimg'>
                        <img src={pr1} class="img-fluid" alt="..." width={400}></img>
                    </div>
                    <h1>CS-PRIDE</h1>
                    <h5>Personal Surveillance Drone</h5>
                    <div>
                    {/* <button type="button" class="btn btn-primary">Download Brochure</button> */}
                    <Link to='/contactus'><button type="button" class="btn btn-primary">Enquire</button></Link>
                    </div>
                    </div>
                </div>
                <div className='CS_prodata'>
                    <div className='CS_proInfo'>
                        <h1>Information</h1>
                        <p>The CS-PRIDE quadcopter emerges as a formidable aerial platform designed specifically for security and surveillance applications. This advanced quadcopter is engineered to provide unparalleled capabilities in monitoring and safeguarding various environments. Equipped with state-of-the-art surveillance technology, CS-PRIDE excels in gathering real-time intelligence and maintaining situational awareness in both urban and remote settings. It's agility and maneuverability enables it to navigate through complex terrain and swiftly respond to potential security threats. With it's ability to cover vast areas and capture high-resolution imagery, this quadcopter plays a pivotal role in safeguarding assets, infrastructure, and public safety.</p>
                    </div>
                    <div className='CS_proApplication'>
                        <div className='CS_application'>
                            <h1>Applications</h1>
                            <figure class="figure">
                                <img src={apppr1} class="figure-img img-fluid rounded" alt="..." />
                                <figcaption class="figure-caption">Photography</figcaption>
                            </figure>
                            <figure class="figure">
                                <img src={apppr2} class="figure-img img-fluid rounded" alt="..." />
                                <figcaption class="figure-caption">Security & Surveillance</figcaption>
                            </figure>
                        </div>
                    </div>
                    <div className='CS_proFeature'>
                        <div className='CS_Feature'>
                            <h1>Features</h1>
                            <div class="container text-center">                               
                                <div class="row justify-content-md-center">
                                    <div class="col col-lg-2">
                                       <h1><IoCamera /></h1>
                                       <h5>4k Camera</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><GiShieldReflect /></h1>
                                       <h5>Obstacle Avoidance</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                       <h1><GiWeight /></h1>
                                       <h5>Total Weight 249 grams</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                       <h1><GiDeliveryDrone /></h1>
                                       <h5>Nano Drone</h5>
                                    </div>
                                </div>                                                                                                                      
                            </div>
                        </div>
                    </div>
                    {/* <div className='Sqaure'></div> */}
                </div>
            </div>
    </>
  )
}

export default CS_pride