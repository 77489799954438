import React from 'react'
import { Link } from "react-router-dom";
import './Footer.css'

const Footer = () => {
  return (
    <>
      <div className='footermain'>
        <div className='footer_container'>
          <div className='foot-items'>
            <h1>PRODUCTS</h1>
            <ul>
              <Link to="krishi"><li>CS-KRISHI</li></Link>
              <Link to="mamba"><li>CS-MAMBA</li></Link>
              {/* <Link to="bhim"><a href='#'><li>CS-BHIM</li></a></Link> */}
              <Link to="bee"><li>CS-BEE</li></Link>
              <Link to="vtol"><li>CS-VTOL</li></Link>
              <Link to="pride"><li>CS-PRIDE</li></Link>
              <Link to="pavanputra"><li>CS-PAVANPUTRA</li></Link>

            </ul>
          </div>
          <div className='foot-items'>
            <h1>SERVICES</h1>
            <ul>
              <Link to="service"><li>AGRICULTURE</li></Link>
              <Link to="service"><li>DISASTER MANAGEMEMNT</li></Link>
              <Link to="service"><li>DELIVERY</li></Link>
              <Link to="service"><li>SURVEILLANCE</li></Link>
              <Link to="service"><li>ARIAL MAPPING</li></Link>
              <Link to="service"><li>POWERLINE INSPECTION</li></Link>
              {/* <Link to="service"><li>SOLAR INSPECTION</li></Link> */}
            </ul>
          </div>
          <div className='foot-items'>
            <h1>ABOUT</h1>
            <ul>
              <Link to="/about"><li>ABOUT US</li></Link>
              <Link to="/gallary"><li>GALLERY</li></Link>
              <Link to="/csiacademy"><li>RPTO</li></Link>
              <Link to="/sparkathon"><li>SPARKATHON</li></Link>
            </ul>
          </div>
          <div className='foot-items'>
          <Link to="/contactus"><h1>CONTACT US</h1></Link>
            <div className='condata'>
              <div><b>Registerd Address:</b>
                <a href='https://maps.app.goo.gl/7po8kcAHo9diYuRU9'> Sr. No 55, Plot No 14, Sai Niwas Society, Pune, Maharashtra, India 411060</a></div>
              <div><b>Email:</b>
                <span> info@cerebrospark.in</span></div>
              <div>
                <b>Mobile No: +91 8600104934 /+91 7387515448</b>
              </div>

            </div>
          </div>

        </div>
        <div className='social'>
          {/* <h1>Connect with us:</h1> */}

          <a href='https://www.instagram.com/cerebrospark?r=nametag'><i class="bi bi-instagram fs-4"></i></a>
          <a href='https://m.facebook.com/cerebrospark/'><i class="bi bi-facebook fs-4"></i></a>
          <a href='https://twitter.com/cerebrospark?s=08'><i class="bi bi-twitter-x fs-4"></i></a>
          <a href='https://www.youtube.com/channel/UCRks6qtOhnmYesN9hwP4GTA'><i class="bi bi-youtube fs-4"></i></a>
          <a href='https://www.linkedin.com/company/cerebrospark-innovations'><i class="bi bi-linkedin fs-4"></i></a>
        </div>
        <div className='Rights d-flex justify-content-center align-items-center'>
          <i class="bi bi-c-circle"> 2024 All rights are reserved by Cerebrospark Innovations Pvt. Ltd.
          </i>
        </div>
      </div>
    </>
  )
}

export default Footer