import React,{useEffect} from "react"
import './About.css'
import AOS from 'aos';
import 'aos/dist/aos.css';


import g1 from '../assets/team/g1.png'
import m1 from '../assets/team/m1.png'
import r1 from '../assets/team/r1.png'
import { FaLinkedin } from "react-icons/fa";

const About = () => {
  useEffect(() => {
        AOS.init();
    })
  return (
    <>
      <div className="Acontainer">
        <div className="AboutUs_data" data-aos="fade-right">
          <h1><span className="ABtitle">ABOUT US</span></h1>
          <h3>WE ARE A DRONE MANUFACTURER AND DRONE SOLUTION PROVIDER!</h3>
          <p>Cerebrospark Innovations is a drone manufacturing and drone solutions providing company based in
            Pune. As
            awareness and demand for drones in various industries increase day by day, we at  Cerebrospark Innovations
              Provide efficient, easy-to-use, and customized drones & drone services. 

            We have manufacturing experience of drones from  80 grams to 100 kg payloads in the domain of 
              Agriculture,
              Security & Surveillance, Delivery, Healthcare, Artificial Intelligence.

            Cerebrospark Innovations focus on  quality-based manufacturing of drones with continuous advancement in
            a
            healthy work environment and enhancement of existing drone technology for various innovative applications in
            all leading industries.
            Cerebrospark Innovations is a recognized leader in the drone industry, with a proven track record of
            delivering  high-quality products and services. Our extensive experience and deep understanding of
            drone
            technology makes us well-equipped to meet and exceed your expectations.

            We offer end-to-end solutions, covering every aspect of drone manufacturing and services. From
            conceptualization and design to production, testing, and deployment, we provide a seamless and
            integrated
            process that ensures exceptional  quality and reliability. Whether you require specialized drone
            configurations, software integration, or customized data analysis, we have the flexibility to deliver exactly
            what you need. We prioritize quality in every aspect of our operations.

            Our manufacturing processes adhere to the  highest industry standards, ensuring our drones are robust,
              durable,
              and built to withstand demanding environments. Our commitment to reliability means you can rely on our
            products and services to perform consistently and meet your expectations.
          </p>

        </div>
        <h1 className="team" data-aos="zoom-in">TEAM</h1>
        <div className="Team_container">
          <div className="Acard_container" data-aos="zoom-right">
            <div className="Acard_data">
              <img src={g1} alt="..." height={200} width={200} />
              <h1>Mr.Ganesh Thorat </h1>
              <h4>(Founder & Chief Executive Officer)</h4>
              <div className='social'>
                        {/* <a href='https://www.instagram.com/cerebrospark?r=nametag'><AiFillInstagram /></a> */}
                        <a href='https://www.linkedin.com/in/ganesh-thorat-963884196?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'><FaLinkedin /></a>
                    </div>
            </div>
          </div>
          <div className="Acard_container" data-aos="zoom-in">
            <div className="Acard_data">
              <img src={m1} alt="..." height={200} width={200} />
              <h1>Mr.Mihir Kedar</h1>
              <h4>(Founder, Chief Marketing Officer &<br/> Chief Technology Officer)</h4>
              <div className='social'>
                        {/* <a href='https://www.instagram.com/cerebrospark?r=nametag'><AiFillInstagram /></a> */}
                        <a href='https://www.linkedin.com/in/mihir-kedar-092286190?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'><FaLinkedin /></a>
                    </div>
            </div>
          </div>
          <div className="Acard_container" data-aos="zoom-left">
            <div className="Acard_data">
              <img src={r1} alt="..." height={200} width={200} />
              <h1>Mr.Rushikesh Sonawane</h1>
              <h4>(Chief Operating Officer)</h4>
              <div className='social'>
                        {/* <a href='https://www.instagram.com/cerebrospark?r=nametag'><AiFillInstagram /></a> */}
                        <a href='https://www.linkedin.com/in/rushikesh-sonawane-0a0934294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'><FaLinkedin /></a>
                    </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default About


