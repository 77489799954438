import React from 'react'
import { Link } from "react-router-dom";
import './Navbar.css'
import logo from '../assets/Csilogo.png'

const Navbar = () => {
    return (
        <>

            <nav class="navbar navbar-expand-lg bg-body-tertiary shadow">
                <div class="container-fluid">
                    <Link to="/"  class="navbar-brand"  ><img src={logo} alt='...' height={60} /></Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <Link to="/"  class="nav-link active" aria-current="page"  >Home</Link>
                            </li>

                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Products
                                </a>
                                <ul class="dropdown-menu">
                                    <li><Link to="/krishi"  class="dropdown-item"  >CS-KRISHI</Link></li>
                                    <li ><Link to="/mamba"  class="dropdown-item"   >CS-MAMBA</Link></li>
                                    {/* <li><Link to="/bhim"  class="dropdown-item"  >CS-Bhim</Link></li> */}
                                    {/* <li><hr class="dropdown-divider"/></li>
                                    <a class="dropdown-item"  >Comig Soon...</a> */}
                                    <li><Link to="/pride"  class="dropdown-item"  >CS-PRIDE</Link></li>
                                    <li><Link to="/bee"  class="dropdown-item"  >CS-BEE</Link></li>
                                    <li><Link to="/vtol"  class="dropdown-item"  >CS-VTOL</Link></li>
                                    <li><Link to="/pavanputra"  class="dropdown-item"  >CS-PAVANPUTRA</Link></li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <Link to="/service"  class="nav-link"  >Services</Link>
                            </li>
                            {/* <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Initiative
                                </a>
                                <ul class="dropdown-menu">
                                    <li><Link to="/csiacademy"  class="dropdown-item"  >CSI-Academy</Link></li>
                                    <li><Link to="/sparkathon"  class="dropdown-item"  >Sparkathon</Link></li>
                                </ul>
                            </li> */}
                            {/* <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    CSA
                                </a>
                                <ul class="dropdown-menu">
                                    <li><Link to="/csiacademy"  class="dropdown-item"  >DGCA RPTO</Link></li>
                                    <li><Link to="/course1"  class="dropdown-item"  >6 Month Course</Link></li>
                                    <li><Link to="/course2"  class="dropdown-item"  >21 Day Course</Link></li>
                                </ul>
                            </li> */}
                            <li class="nav-item">
                                <Link to="/csiacademy"  class="nav-link"  >RPTO</Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/sparkathon"  class="nav-link"  >Sparkathon</Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/about"  class="nav-link"  >About</Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/gallary"  class="nav-link"  >Gallery</Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/contactus"  class="nav-link"  >Contact Us</Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>

        </>
    )
}

export default Navbar