import React from 'react'
import { SlideshowLightbox } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import './Gallary.css'

import meet1 from '../assets/Gallary/Meetup/1.png'
import meet2 from '../assets/Gallary/Meetup/2.png'
import meet3 from '../assets/Gallary/Meetup/3.png'
import meet4 from '../assets/Gallary/Meetup/4.png'
import meet5 from '../assets/Gallary/Meetup/5.png'
import meet6 from '../assets/Gallary/Meetup/6.png'
import meet7 from '../assets/Gallary/Meetup/7.png'
import meet8 from '../assets/Gallary/Meetup/8.png'
import meet9 from '../assets/Gallary/Meetup/9.png'


const Meetup = () => {
  return (
    <>
      <div className='gallary'>
                {/* <h1><span>All About The CSI</span></h1> */}
                <SlideshowLightbox className='container gallaryContainer grid grid-cols-3 gap-2 mx-auto'>
                    <img className='w-full rounded' src={meet1} alt='...' />
                    <img className='w-full rounded' src={meet2} alt='...' />
                    <img className='w-full rounded' src={meet3} alt='...' />
                    <img className='w-full rounded' src={meet4} alt='...' />
                    <img className='w-full rounded' src={meet5} alt='...' />
                    <img className='w-full rounded' src={meet6} alt='...' />
                    <img className='w-full rounded' src={meet7} alt='...' />
                    <img className='w-full rounded' src={meet8} alt='...' />
                    <img className='w-full rounded' src={meet9} alt='...' />
                    
                </SlideshowLightbox>
            </div>
    </>
  )
}

export default Meetup
