import React from 'react'
import './Course1.css'

import logo1 from '../assets/course21d/ICARlogo.png'
import logo2 from '../assets/course21d/NAHEPLogo.png'
import logo3 from '../assets/course21d/VNMKV logo.png'
import logo4 from '../assets/Csilogo.png'

import img1 from '../assets/course6m/Enterpreuner.png'
import img2 from '../assets/course6m/dpilot.png'
import img3 from '../assets/course6m/droneDev.png'
import img4 from '../assets/course6m/dtester.png'
import img5 from '../assets/course6m/gov.png'
import img6 from '../assets/course6m/private.png'
import img7 from '../assets/course6m/trainer.png'
import img8 from '../assets/course6m/uaveng.png'


const Course1 = () => {
    return (
        <>
            <div className="container">
                <div className="c6m-hero text-center">
                    <div className='course21d-logo'>
                        <img src={logo3} class="img-fluid" alt="..." />
                        <img src={logo1} class="img-fluid" alt="..." />
                        <img src={logo2} class="img-fluid" alt="..." />
                        <img src={logo4} class="img-fluid" alt="..." />
                    </div>

                    <div className='text-center'>
                        <h5 >Vasantrao Naik Marathwada krishi Vidyapeeth, Parbhani with Cerebrospark Innovations, Pune Presents</h5>
                        <h1>DRONES IN AGRICULTURE</h1>
                        <h6>A PROFESSIONAL CERTIFICATION COURSE </h6>
                    </div>
                </div>

                <div className="co my-4">
                    <h1 className='text-center heading-center'>Course Outcomes</h1>
                    <div className='d-flex'>
                        <div className='d-flex flex-wrap justify-content-center'>
                            <div className='co-sub border border-1 border-black m-2 p-2 '> <h6><b>Understanding drones:</b><br />Types, applications, rules, regulations, and safety precautions.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2 '> <h6><b>Principles of flight:</b><br />Understanding flight principles and various laws and processes.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2 '> <h6><b>Aerodynamics:</b><br />Study of wings, propellers, and flight mechanisms.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2 '> <h6><b>Mechanical components:</b><br />Learning about various mechanical parts of drones.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2 '> <h6><b>Electronic components:</b><br />Studying the electronic parts, their connections, and functions within a drone.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2 '> <h6><b>Drone repair:</b><br />Hands-on learning to disassemble, repair, rebuild, and reassemble a standard drone.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2 '> <h6><b>Electrical parameters:</b><br />Documenting electrical parameters of drone hardware.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2 '> <h6><b>Drone operation skills:</b><br />Becoming a skilled drone operator through theoretical and practical flying sessions.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2'><h6><b>Agriculture drones:</b><br />Learning about the working of agriculture-specific drones.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2'><h6><b>Crop-specific SOP:</b><br />Understanding standard operating procedures for agriculture drones.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2'><h6><b>Modern vs. traditional farming:</b><br />Understanding the advantages of modern drone farming over traditional methods.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2'><h6><b>New agricultural technology:</b><br />Learning about technology for efficient and modern agriculture.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2'><h6><b>Innovative agricultural uses:</b><br />Discovering how drones are revolutionizing agriculture.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2'><h6><b>Future scope:</b><br />Understanding the future potential of the drone industry.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2'><h6><b>Business potential:</b><br />Unlocking business opportunities in the drone industry.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2'><h6><b>Innovation and entrepreneurship:</b><br />Fostering innovation and entrepreneurial spirit.</h6></div>
                            <div className='co-sub border border-1 border-black m-2 p-2'><h6><b>Design and calculation:</b><br /><br />Learning design and calculation of drone components for various applications.</h6></div>
                        </div>

                    </div>

                    <div className='text-center'>
                        <h4 className='text-center mt-5'>★VNMKV & CSI will provide you the assistance in starting your own drone business & job assistance in following roles.</h4>
                        <div className='d-flex text-start justify-content-evenly'>

                            <div className='d-flex flex-wrap flex-column justify-content-center'>
                                <div className='card-parent m-4'>
                                    <div class="card cards6m p m-3">
                                        <img src={img2} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title">Drone Pilot</h5>
                                        </div>
                                    </div>
                                    <div class="card cards6m m-3">
                                        <img src={img3} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title">UAV Developer</h5>
                                        </div>
                                    </div>
                                    <div class="card cards6m m-3">
                                        <img src={img4} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title">Drone Tester</h5>
                                        </div>
                                    </div>

                                    <div class="card cards6m m-3">
                                        <img src={img7} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title">Drone trainer</h5>
                                        </div>
                                    </div>
                                </div>


                                <div className='card-parent m-4'>
                                    <div class="card cards6m m-3">
                                        <img src={img8} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title">UAV Engineer</h5>
                                        </div>
                                    </div>
                                    <div class="card cards6m m-3">
                                        <img src={img1} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title">Entrepreneur</h5>
                                        </div>
                                    </div>
                                    <div class="card cards6m m-3">
                                        <img src={img5} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title">Government Sector</h5>
                                        </div>
                                    </div>
                                    <div class="card cards6m m-3">
                                        <img src={img6} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title">Private Sector</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="highlights-course6m my-4">
                    <h1 className='text-center heading-center'>Highlights</h1>
                    <div className='d-flex flex-column justify-content-center'>
                        <div className='c6m-highlights1 m-1 d-flex justify-content-center'>
                            <div className='c6m-sub p-3 m-3 border border-1 border-black'>
                                <i class="text-primary bi bi-lightning-charge"></i><b> Entrepreneurial Opportunities:</b><br /> •  Students will be equipped to start their own businesses in the drone sector.
                            </div>
                            <div className='c6m-sub p-3 m-3 border border-1 border-black'>
                                <i class="text-primary bi bi-patch-check"></i><b> Industry-Relevant Content:</b><br /> The course content is applicable to the drone manufacturing and service industry.
                            </div>
                            <div className='c6m-sub p-3 m-3 border border-1 border-black'>
                                <i class="text-primary bi bi-gear"></i><b> Drone Repair and Maintenance Certificate:</b><br /> •  Students will also receive certification in drone repair and maintenance.
                            </div>
                            <div className='c6m-sub p-3 m-3 border border-1 border-black'>
                                <i class="text-primary bi bi-person-workspace"></i><b> Placement Assistance:</b><br /> •  Students will receive placement assistance and career opportunities in various drone industries.
                            </div>
                        </div>

                        <div className='d-flex justify-content-center c6m-highlights2'>
                            <div className='c6m-sub p-3 m-3 border border-1 border-black'>
                                <i class="text-primary bi bi-award"></i><b> DGCA Certified Remote Pilot Certificate (RPC):</b><br /> •  Students will receive a  Remote Pilot Certificate, certified <br /> by the Directorate General of Civil Aviation (DGCA).
                            </div>
                            <div className='c6m-sub p-3 m-3 d-flex justify-content-center w-auto border border-1 border-black'>
                                <b><i class="text-primary bi bi-wallet2"></i> Fees:</b>
                                <br /> • Students can pay there fee in installments.
                                <br /> • First professional certificate course of 6 month.
                                <br /> • Theoretical & hands on knowledge of drones.
                                <br /> • Practical flying skills.
                                <br /> • Future scope in drone industries & drone services
                                <br /> • Design & make your own drone.
                                <br /> • Only 20 seats available per batch.
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className='bg-primary-subtle py-3 my-4  text-center'><button className='btn btn-primary text-white'><a href="https://forms.gle/67zGWQgHCdpatR8x8" className='text-white'>Register Now</a></button></h5>

                <div className="facilities6m my-4">
                    <h1 className='text-center heading-center '>Facilities</h1>
                    <div className='facilities6m-div1 d-flex my-3'>
                        <ul>
                            <li> Drone laboratory with various drones, drones assembly & dismantling kit.</li>
                            <li> Latest flight simulator, smart-class room & lecture hall</li>
                            <li> DGCA approved drones.</li>
                            <li> Highly skilled & experienced instructors</li>
                        </ul>
                        <div className="fees-eligibility mx-auto">
                            <table>
                                <thead className='bg-primary-subtle'>
                                    <th>Sr. No.</th>
                                    <th>Particulars</th>
                                    <th>Fees</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Registration Fees</td>
                                        <td>Rs.3000</td>
                                    </tr>
                                    <tr className='bg-primary-subtle'>
                                        <td>2</td>
                                        <td>Course Fees</td>
                                        <td>Rs.96000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="faculty d-flex flex-column justify-content-center m-auto">
                    <h1 className='text-center heading-center'>
                        List of Faculties for Course
                    </h1>
                    <table>
                        <thead className='bg-primary-subtle'>
                            <th>Sr.No</th>
                            <th>Name</th>
                            <th>Details</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Dr.Indra Mani</td>
                                <td>Vice Chanceller, VNMKY</td>
                            </tr>
                            <tr className='bg-primary-subtle'>
                                <td>2</td>
                                <td>Dr.G.U Shinde</td>
                                <td>PI-DFSRDA VNMKV</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Mr. Mihir Kedar</td>
                                <td>CTO & DGCA Instructor, Cerebrospark</td>
                            </tr>
                            <tr className='bg-primary-subtle'>
                                <td>4</td>
                                <td>Mr. Rushikesh Sonawane</td>
                                <td>COO & DGCA Instructor, Cerebrospark</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Mr. Prasad Girbanwad</td>
                                <td>Agri Instructor, Cerebrospark</td>
                            </tr>
                            <tr className='bg-primary-subtle'>
                                <td>6</td>
                                <td>Mr. Ganesh Thorat</td>
                                <td>CEO & Instructor, Cerebrospark</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Dr. V. K. Ingle</td>
                                <td>Co-PI, Agri-Drone Division VNMKV</td>
                            </tr>
                            <tr className='bg-primary-subtle'>
                                <td>8</td>
                                <td>Er.S.V. Muley</td>
                                <td>Teaching Associate & Research Associate DFSRDA</td>
                            </tr>
                        </tbody>
                    </table>

                    <h5 className='bg-primary-subtle py-3 my-4 text-center'>Various Faculties From IIT's, & International Universities.</h5>

                    <div className='course6m-detail text-center mt-2'>
                        <h1 className='text-center heading-center'> For More Details Contact on</h1>
                        <div className='sub-detail6m d-flex justify-content-evenly gap-3'>

                            <div className='border border-1 border-black p-3 rounded'>
                                <b> Vasantrao Naik Marathwada Krishi Vidyapeeth, Parbhani - 431402
                                    <br /> Ph. - +91 9422111232.</b>
                                <br /><button className='btn btn-primary'><a href="https://nahep.vnmkv.org.in" className='text-white'>Visit Now</a></button>
                            </div>

                            <div className='border border-1 border-black p-3 rounded'>
                                <b> Cerebrospark Innovations Pvt. Ltd., Pune-411060
                                    <br /> Ph.- +91 8600104934/ 7387515448/ 7499884991.</b>
                                <br /><button className='btn btn-primary'><a href="https://cerebrospark.in/" className='text-white'>Visit Now</a></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Course1
